import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import HeaderSplit from '../components/header/HeaderSplit';
import Preloader from '../components/Preloader';
import SignupComponent from '../components/section/SignupComponent';
import useApiUrl from '../framework/hooks/useApiUrlHook';
import usePage from '../framework/hooks/usePageHook';

import '../components/scss/SignUpMemberSection.scss';
import MemberBlock from '../components/section/members/MemberBlock';

export default function SignupPage({ page }) {
    const pageObj = usePage(page.id);
    const params = useParams();
    const [branches, setBranches] = useState(null);
    const [members, setMembers] = useState(null);
    const [event, setEvent] = useState(null);
    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getBranches() {
            if (apiUrl === null) return;

            await axios.get(`${apiUrl}/members/branches/100`).then((response) => {
                setBranches(response.data);
            });
        }

        async function getMembers(id) {
            if (apiUrl === null) return;

            await axios.get(`${apiUrl}/members/event/${id}`).then((response) => {
                console.log(response.data?.event);
                const leGrid = response.data?.members?.map((item, index) => {
                    const label =
                        item.id === response.data?.event?.spreker ? (
                            <>
                                <span
                                    style={{
                                        color: '#fff',
                                        backgroundColor: 'var(--color-emphasis)',
                                        padding: '2px 10px',
                                        borderRadius: '15px',
                                        fontSize: '10px',
                                    }}
                                >
                                    Spreker
                                </span>
                                <br />
                            </>
                        ) : (
                            ''
                        );
                    return <MemberBlock key={index} item={item} label={label} />;
                });
                setEvent(response.data?.event);
                setMembers(leGrid);
            });
        }

        if (members === null && params.id) {
            getMembers(params.id);
        }

        if (branches === null) {
            getBranches();
        }
    }, [apiUrl]);

    if (!pageObj) return <Preloader />;

    const image = pageObj.images.split(',')[0];

    const getExtraContent = () => {
        return (
            <>
                <h2>Werkzaam in een van de vrije branches? Meld je aan!</h2>
                <div className="branches">
                    {branches?.map((item, key) => (
                        <span key={key}>{item.titel}</span>
                    ))}
                </div>
            </>
        );
    };

    const getMembersContent = () => {
        if (!members) return '';

        return (
            <section className="signupMembers w-100">
                <div className="underlineTitle active">
                    <h1 style={{ marginBottom: 0 }}>Sprekers</h1>
                </div>
                <h2>
                    {event?.title} - {event?.date} uur
                </h2>

                <div className="container w-100">
                    <div className="row">
                        <div className="col col--12">
                            <div className="gridContainer w-100">{members}</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    return (
        <HeaderSplit
            title={pageObj.titletag !== '' ? pageObj.titletag : pageObj.titel}
            image={`${apiUrl}/img/ratio/website/888/666/100/${image}`}
            content={pageObj.inhoud}
            extraContent={getExtraContent()}
            extraPreContent={getMembersContent()}
            ignoreOrder={params.urlTitle === 'info'}
        >
            <SignupComponent />
        </HeaderSplit>
    );
}
