import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import '../scss/header/HeaderSimple.scss';
import '../scss/header/HeaderSplit.scss';

export default function HeaderSplit({ title, content, image, children, extraContent = null, extraPreContent = null, ignoreOrder = false }) {
    const [isInView, setIsInView] = useState(false);

    const [rawTitle, setTitle] = useState();
    const [rawContent, setContent] = useState();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        setTitle(title);
        setContent(content);
    }, [title, content]);

    return (
        <header className="headerSimple headerSplit">
            <Waypoint topOffset="25%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className={`col col--7 ${ignoreOrder ? 'ignore-order' : ''}`}>
                            {extraPreContent}
                            <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                <h1>
                                    {rawTitle !== undefined && rawTitle.length > 0 ? (
                                        <ApolloMarkdown inline>{rawTitle}</ApolloMarkdown>
                                    ) : (
                                        ''
                                    )}
                                </h1>
                            </div>
                            <p>
                                {rawContent !== undefined && rawContent.length > 0 ? (
                                    <ApolloMarkdown>{rawContent}</ApolloMarkdown>
                                ) : (
                                    ''
                                )}
                            </p>
                            {extraContent}
                            <div className="image">
                                <img src={image} alt="" />
                            </div>
                        </div>
                        <div className={`col col--5 ${ignoreOrder ? 'ignore-order' : ''}`}>{children}</div>
                    </div>
                </div>
            </Waypoint>
        </header>
    );
}
