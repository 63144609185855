import { Link } from "react-router-dom";
import useApiUrl from "../../../framework/hooks/useApiUrlHook";

export default function MemberBlock({ item, label = null }) {
    const apiUrl = useApiUrl();

    return (
        <div className="gridItem">
            <div className="inner">
                <img
                    src={`${apiUrl}/img/extern/ratio/www.pngtwente.nl/profielfoto/500/500/100/${item.image}`}
                    alt=""
                />
                <div className="text">
                    <div className="wrap">
                        {item.quote !== null ? <div className="quote">{item.quote}</div> : ''}
                        <div className="name">
                            {label} {item.contactpersoon}
                        </div>
                        <div className="company">{item.bedrijfsnaam}</div>
                    </div>
                    <Link
                        to={`/onze-leden/${item.id}/${encodeURIComponent(
                            `${item.contactpersoon} van ${item.bedrijfsnaam}`
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/\*/g, ''),
                        )}`}
                        className="button"
                    >
                        <div className="btn">Lees mijn verhaal</div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
