import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

import './scss/signupComponent.scss';

export default function SignupComponent() {
    const params = useParams();

    const [members, setMembers] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [lightBoxVisible, setLightBoxVisible] = useState(false);
    const [page, setPage] = useState('form');

    const apiUrl = useApiUrl();
    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function getMembers() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/members`)
                .then((response) => {
                    let array = response.data;

                    array.sort((a, b) => {
                        const textA = a.contactpersoon.toUpperCase();
                        const textB = b.contactpersoon.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                    });

                    setMembers(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }

        if (members === null) {
            getMembers();
        }
    }, [apiUrl]);

    useEffect(() => {
        async function getCalendar() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/calendar`)
                .then((response) => {
                    setCalendar(response.data);

                    if (params.id) {
                        setValue('calendar', params.id);

                        if (params.urlTitle === 'aanmelden') {
                            setFocus('fullname');
                        }
                    }
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }
        if (calendar === null) {
            getCalendar();
        }
    }, [calendar, apiUrl]);

    const onSubmit = (data) => {
        if (apiUrl === null) {
            alert('Kon het formulier niet verzenden, probeer over een paar seconden nogmaals.');
            return;
        }

        axios
            .post(`${apiUrl}/calendar/addGuest`, {
                ...data,
            })
            .then((response) => {
                if (response.data) {
                    setPage('bedankt');
                } else {
                    setPage('fout');
                }
            });
    };

    const handleToggleLightbox = () => {
        setLightBoxVisible(!lightBoxVisible);
    };

    return (
        <div className="signupComponentWrap">
            <section className="signupComponent prettyForm" onFocus={() => setLightBoxVisible(true)}>
                {page === 'form' ? (
                    <div className="inner">
                        <div className="underlineTitle active">
                            <h2>
                                Ik wil me <strong>aanmelden</strong> voor een bijeenkomst
                            </h2>
                            <hr />
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <div className="formRow">
                                    <label>
                                        <span>Voor- en achternaam:</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Voor- en achternaam"
                                        {...register('fullname', {
                                            required: true,
                                            min: 5,
                                            pattern:
                                                /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/i,
                                        })}
                                    />
                                </div>
                                <div className="formRow">
                                    <label>
                                        <span>Bedrijfsnaam:</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Bedrijfsnaam"
                                        {...register('company', { required: true, min: 5 })}
                                    />
                                </div>
                                <div className="formRow">
                                    <label>
                                        <span>Bij te wonen bijeenkomst:</span>
                                    </label>
                                    <div className="selectWrap">
                                        <select {...register('calendar', { required: true })}>
                                            <option selected disabled>
                                                Maak een keuze
                                            </option>
                                            {calendar?.map((obj, index) => {
                                                return (
                                                    <option key={index} value={obj.id}>
                                                        {dayjs(obj.date_start).locale('nl').format('DD MMMM YYYY, hh:mm')} -{' '}
                                                        {obj.titel}
                                                    </option>
                                                );
                                            }) ?? null}
                                        </select>
                                    </div>
                                </div>
                                <div className="formRow">
                                    <label>
                                        <span>Als gast van:</span>
                                    </label>
                                    <div className="selectWrap">
                                        <select {...register('sponsor')}>
                                            <option selected disabled>
                                                Maak een keuze
                                            </option>
                                            {members?.map((obj, index) => {
                                                return (
                                                    <option key={index} value={obj.id}>
                                                        {obj.contactpersoon} - {obj.bedrijfsnaam}
                                                    </option>
                                                );
                                            }) ?? null}
                                        </select>
                                    </div>
                                </div>
                                <div className="formRow">
                                    <label>
                                        <span>Emailadres (voor correspondentie):</span>
                                    </label>
                                    <input
                                        type="email"
                                        placeholder="Emailadres"
                                        {...register('email', {
                                            required: true,
                                            pattern:
                                                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
                                        })}
                                    />
                                </div>
                                <div className="formRow">
                                    <label>
                                        <span>Telefoonnummer (voor correspondentie):</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Telefoonnummer"
                                        {...register('phone', {
                                            required: true,
                                            pattern:
                                                /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/i,
                                        })}
                                    />
                                </div>
                            </div>

                            <input type="submit" value="aanmelden" />
                        </form>
                    </div>
                ) : null}

                {page === 'bedankt' ? (
                    <div className="inner">
                        <div className="underlineTitle active">
                            <h2>
                                Bedankt voor je <strong>aanmelding!</strong>
                            </h2>
                            <hr />
                        </div>
                        <p>Je ontvangt de ontvangstbevestiging per email.</p>
                    </div>
                ) : null}

                {page === 'fout' ? (
                    <div className="inner">
                        <div className="underlineTitle active">
                            <h2>
                                Er ging <strong>iets mis!</strong>
                            </h2>
                            <hr />
                        </div>
                        <p>Herlaadt de pagina en vul het formulier opnieuw in.</p>
                    </div>
                ) : null}
            </section>
            <div className={`lightBox ${lightBoxVisible ? "active" : ""}`} onClick={handleToggleLightbox} />
        </div>
    );
}
